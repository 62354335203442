import React, { useState, useEffect } from "react";
import "./viewDemo.css";
import zdLogo from "../../assets/zd viewer beta logo whiite.png";
import "../../fonts/good times rg.otf";
import "../../fonts/Segoe UI.ttf";
import bikegif from "../../assets/Ducati  slow speed Resolution 1.gif";
import dressImg from "../../assets/dressImg.png";
import lamboImg from "../../assets/dd.png";
import CardComponent from "./components/cardComponent";
import sofaImg from "../../assets/sofaImg.png";
import Footer from "../../components/footer";
import laptop from "../../assets/Laptop.gif";
import Popup from "reactjs-popup";
import Input from "../../components/input";
import mask from "../../assets/MaskGroup2.png";
import backgroundImg from "../../assets/Group 90.png";
import mobBackgroundImg from "../../assets/Group 92.png";
import cancelIcon from "../../assets/xicon.png";
import arrowImg from "../../assets/IMG.png";

import { Fade } from "react-awesome-reveal";
import { useNavigate } from "react-router";
import Muibutton from "../../components/Button";
import { arrow } from "@popperjs/core";

const ViewDemo = () => {
  const [isDisable, setisDisable] = useState(false);

  const [name, setName] = useState("");
  const [Lname, setLName] = useState("");
  const [mobile, setMobile] = useState("");
  const [company, setCompany] = useState("");
  const [email, setEmail] = useState("");
  const [nameError, setNameError] = useState();
  const [LnameError, setLNameError] = useState();
  const [companyError, setCompanyError] = useState();
  const [mobileError, setMobileError] = useState();
  const [emailError, setEmailError] = useState();
  const [message, setMessage] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedFruit, setSelectedFruit] = useState("");
  const [requirements, setRequirements] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isVisible, setIsVisible] = useState(false);
  const [matches, setMatches] = useState(
    window.matchMedia("(max-width: 1099px)").matches
  );
  const [mobileRes, setmobileRes] = useState(
    window.matchMedia("(max-width: 1024px)").matches
  );
  const navigate = useNavigate();

  useEffect(() => {
    document.body.style.overflow = isDisable ? "hidden" : "unset";
  }, [isDisable]);

  useEffect(() => {
    window
      .matchMedia("(max-width: 1099px)")
      .addEventListener("change", (e) => setMatches(e.matches));
  }, []);

  useEffect(() => {
    window
      .matchMedia("(max-width: 1024px)")
      .addEventListener("change", (e) => setmobileRes(e.matches));
  }, []);

  const onSubmit = async () => {
    console.log("name>>>>", name);
    console.log("mobile>>>", mobile);
    console.log("company>>>", company);
    console.log("email>>>>", email);
    let nameErrorMessage = "";
    if (name) {
      if (name.length < 2) {
        nameErrorMessage = "Please enter valid first name.";
      }
    } else {
      nameErrorMessage = "Please enter your first name.";
    }
    setNameError(nameErrorMessage);

    let nameErrorMessage2 = "";
    if (Lname) {
      if (Lname.length < 1) {
        nameErrorMessage2 = "Please enter valid last name.";
      }
    } else {
      nameErrorMessage2 = "Please enter your last name.";
    }
    setLNameError(nameErrorMessage2);

    let companyErrorMessage = "";
    if (company) {
      if (company.length < 2) {
        companyErrorMessage = "Please enter valid company name.";
      }
    } else {
      companyErrorMessage = "Please enter company name.";
    }
    setCompanyError(companyErrorMessage);

    let mobileErrorMessage = "";
    // const regMobile = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
    const regMobile = /^\+?\d{1,4}(?:[-. ]\d{1,4})?\s?\d{9,12}$/;

    if (mobile) {
      if (!regMobile.test(mobile)) {
        mobileErrorMessage = "Please enter valid mobile number.";
      }
    } else {
      mobileErrorMessage = "Please enter mobile number.";
    }
    setMobileError(mobileErrorMessage);
    const mobileValidate = regMobile.test(mobile);

    ////////////////////////////////

    if (requirements.trim() === "") {
      setErrorMessage("Please enter a requirement.");
    } else if (requirements.length > 200) {
      setErrorMessage("Requirement should not exceed 200 characters.");
    } else {
      setErrorMessage("");
      console.log("Requirement is valid.");
      // Perform further actions or submit the form if needed
    }

    //////////////////////////////////

    let emailErrorMessage = "";
    const regEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;
    if (email) {
      if (!regEmail.test(email)) {
        emailErrorMessage = "The format of your email is incorrect.";
      }
    } else {
      emailErrorMessage = "Please enter email address.";
    }
    setEmailError(emailErrorMessage);
    const emailValidate = regEmail.test(email);

    if (
      name.length < 2 ||
      mobileValidate === false ||
      company.length < 2 ||
      emailValidate === false ||
      requirements < 2 ||
      Lname < 1
    ) {
      console.log("Please enter valid details.");
    } else {
      console.log("Success");
      setisDisable(false);
      setIsVisible(true);

      setName("");
      setLName("");
      setCompany("");
      setMobile("");
      setEmail("");
      setRequirements("");

      const bodyData = {
        name: name + " " + Lname,
        company: company,
        mobile: mobile,
        email: email,
        requirements: requirements,
      };

      const recipient = "info@zdviewer.com";
      // const recipient = "amboji.a@ziniosedge.com";
      const subject = "ZDViewer - Request for Demo";

      const url = "https://zdvservice.azurewebsites.net/api/landing/email";

      let body = JSON.stringify(bodyData);
      try {
        const response = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ recipient, subject, body }),
        });

        const data = await response.json();
        console.log("RESPONSE>>>>>>", data.message);

        setMessage(true);
      } catch (error) {
        setMessage(false);
        console.log("RESPONSE>>>>>>", "Failed to send email");
      }
    }
  };

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const selectFruit = (fruit) => {
    setSelectedFruit(fruit);
    setIsOpen(false);
  };

  const handleChange = (event) => {
    setRequirements(event.target.value);
    setErrorMessage("");
  };

  // const bagroundImg = useProgressiveImage(BackgroundImg);
  // const mobBagroundImg = useProgressiveImage(BackgroundImg);

  // const navigate = useNavigate();
  const array = [
    {
      id: 1,
      imgSrc: lamboImg,
      heading: "AUTOMOBILE",
      body: "Transform the way you showcase your vehicles with our premier 3D viewing service for the automotive industry. Allow customers to personalize their dream car in real-time, swapping colors, wheels, and interiors by highlight options and features with interactive hotspots and annotations.",
      buttonText: "View Demo",
      link: "https://staging.view.zdviewer.com/products/CAR_CONFIG-2",
    },
    {
      id: 2,
      imgSrc: dressImg,
      heading: "FASHION",
      body: "Revolutionize the way you experience and interact with fashion. 360° product view allows your customers to virtually walk around your fashion products, zoom in on intricate details, and appreciate the true craftsmanship of your designs.",
      buttonText: "View Demo",
      link: "https://staging.view.zdviewer.com/products/CLOSET",
    },
    {
      id: 3,
      imgSrc: sofaImg,
      heading: "INTERIOR",
      body: "We bring your products to life like never before, allowing customers to explore, customize, and visualize furniture in their own homes before they buy at just a click of a button. We work towards creating custom solutions that showcase your prized posession and boost sales.",
      buttonText: "View Demo",
      link: "https://staging.view.zdviewer.com/products/SOFA",
    },
  ];

  return (
    <>
      <div className="viewDemo_container">
        <div className="bike_group_90a">
          <div className="bike_screen">
            <div className="viewDemo_content">
              <div className="ViewDemo_heading ">
                EXPLORE OUR INTERACTIVE 3D DEMOS
              </div>
              <div className="viewDemogif_container">
                <div className="viewDemo_gif">
                  <img src={bikegif} alt="bike gif" />
                </div>
                {/* <div className="viewDemo_button SegoeUI">View Demo</div> */}
              </div>
            </div>

            <div className="viewDemo_zdlogo" onClick={() => navigate("/")}>
              <img src={zdLogo} alt="zdLogo" />
            </div>
          </div>

          <div className="card_screen">
            <div className="cardScreen_heading ">
              <div style={{ width: "50rem" }}>
                IMMERSIVE EXPERIENCE LEADS TO TANGIBLE OUTCOMES
              </div>
            </div>
            <Fade triggerOnce>
              <div className="card_container">
                {array.map((item, index) => {
                  return (
                    <CardComponent
                      key={index}
                      imgSrc={item.imgSrc}
                      heading={item.heading}
                      body={item.body}
                      buttonText={item.buttonText}
                      link={item.link}
                    />
                  );
                })}
              </div>
            </Fade>
          </div>

          <div className="shopping_screen">
            <div className="shopping_text">
              <div className="shopping_header_text">
                ONLINE SHOPPING EXPERIENCES
              </div>
              <div className="shopping_description">
                Utilizing 3D Technologies for Advanced Design, Efficient
                Production, and Engaging Customer Interactions: Transforming
                E-Commerce with Life like Product Visualizations, Elevating
                Manufacturing with Precision Engineering, and Redefining
                Automotive Design, all leading to Increased Efficiency,
                Innovation, and Enhanced Customer Engagement
              </div>
            </div>
            <div className="shopping_gif_container">
              <div className="pdp_gif">
                <img src={laptop} alt="bike gif" />
              </div>
              <div className="shopping_button" onClick={() => navigate("/pdp")}>
                VIEW DEMO
              </div>
            </div>
          </div>
        </div>
        <div
          className="schedule_demo_screen"
          // style={{ backgroundColor: "#024040" }}
        >
          <div className="schedule_demo_container">
            <div className="left_design">
              <div className="left_curve"></div>
            </div>
            <div className="schedule_box">
              <div className="top_design">
                <div className="left_curve"></div>
              </div>
              <div className="schedule_text_container">
                <div className="text">
                  Are there any specific use cases you're considering?
                </div>
                <div className="description">Let's explore them together!</div>
                <Muibutton
                  text="Contact us"
                  backgroundColor="#ffd603"
                  backgroundOnHover="#eac404"
                  ColorText="#000000"
                  BorderRadius="0.5rem"
                  fontWeight="600"
                  padding="0.2rem 1.5rem"
                  onClick={() => setisDisable(true)}
                />
              </div>
              <div className="bottom_design">
                <div className="left_curve"></div>
              </div>
            </div>
            <div className="right_design">
              <div className="left_curve"></div>
            </div>
          </div>
        </div>

        <div //style={{ backgroundColor: "#024040", width: "100%" }}
        >
          <Footer />
        </div>
      </div>
      <Popup
        contentStyle={{
          // width: "71.75rem",
          width: window.innerWidth < 1024 ? "95%" : "60%",
          display: " flex",
          flexDirection: "column",
          padding: "0rem",
          alignItems: "center",
          backgroundImage: `url(${mask})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundColor: "#000821",
          boxShadow: "0rem 0.001rem 2rem grey",
          borderRadius: "1.125rem",
        }}
        open={isDisable}
        closeOnDocumentClick={false}
      >
        <div
          className="close-icon"
          onClick={() => {
            setisDisable(false);
            setName("");
            setLName("");
            setCompany("");
            setMobile("");
            setEmail("");
            setNameError("");
            setCompanyError("");
            setMobileError("");
            setEmailError("");
            setRequirements("");
            setLNameError("");
            setErrorMessage("");
          }}
        >
          <img src={cancelIcon} alt="cancel-btn" />
        </div>
        <div className="popup-header">
          Please fill in your details we will contact you!
        </div>

        <Input
          label={"First Name*"}
          value={name}
          onChange={(e) => setName(e.replace(/[^A-Za-z]/gi, ""))}
          errorMsg={nameError}
          type={"text"}
        />
        <Input
          label={" Last Name*"}
          value={Lname}
          onChange={(e) => setLName(e.replace(/[^A-Za-z]/gi, ""))}
          errorMsg={LnameError}
          type={"text"}
        />
        <Input
          label={"Mobile Number*"}
          value={mobile}
          onChange={(e) => setMobile(e.replace(/\D/g, ""))}
          errorMsg={mobileError}
          inputMode={"numeric"}
          type={"text"}
        />
        <Input
          label={"Company Name*"}
          value={company}
          onChange={(e) => setCompany(e.target.value.trim())}
          errorMsg={companyError}
          type={"text"}
        />
        <Input
          label={"Company Email ID*"}
          value={email}
          onChange={(e) => setEmail(e.replace(" ", ""))}
          errorMsg={emailError}
          type={"text"}
        />
        <div className="user-details">
          <div className="user-text">Requirement*</div>
          <div className="mobile-num-container">
            <textarea
              id="requirements"
              className="textarea"
              value={requirements}
              onChange={handleChange}
            />
            {errorMessage && <div className="error-msg">{errorMessage}</div>}
          </div>
        </div>

        <div className="submit" onClick={() => onSubmit()}>
          Submit
        </div>
      </Popup>
    </>
  );
};

export default ViewDemo;
